.footer {
    width: 100%;
    height: 100px;
    background-color: #e5d9d1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .socialMedia svg {
    color: #6b1c3b;
    margin: 0px;
    font-size: 40px;
    cursor: pointer;
  }
  
  .footer p {
    color: #6b1c3b;
  }

  .footer button {
    background-color: transparent;
    border: none;
    color: #6b1c3b;
    cursor: pointer;
  }
