

.galleryPage {
    width: 100%;
    margin-top: 5px;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;    
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

iframe {
  width: 800px;
  height: 600px;
}

  @media only screen and (max-width: 1300px) {
    iframe {
      width: 800px;
      height: 600px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    iframe {
      width: 60vh;
    }
  }