.home {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .headerContainer {
    max-width: 100%;
    height: 1700px;
    background-size: cover;
  }
  
  .headerContainer h1 {
    justify-content: center;
    align-items: center;
    font-size: 90px;
    padding-left: 60px;
    height: 60px;
    font-weight: 600;
    color: #6b1c3b;
  }
  
  .headerContainer p {
    padding: 0;
    padding-left: 60px;
    padding-top: 30px;
    font-size: 40px;
    font-weight: lighter;
    color: #e5d9d1;
  }
  
  .headerContainer button {
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #121619;
    height: 50px;
    width: 180px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .headerContainer button:hover {
    background-color: #07090a;
    cursor: pointer;
    transition: 0.3s ease-in;
  }

    /* --------- SMALL WINDOW --------- */
  
  @media only screen and (max-width: 700px) {
    .home {
      justify-content: center;
      align-items: center;
      height: 74vh;
    }
  
    .headerContainer {
      margin-left: 0px;
      border-radius: 0px;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
    }
  
    .headerContainer h1 {
      font-size: 40px;
      font-weight: 600;
      padding-left: 0px;
      height: 30px;
      color: #e5d9d1;
    }
  
    .headerContainer p {
      font-size: 30px;
      font-weight: 400;
      padding-left: 0px;
      color: #e5d9d1;
      display: none;
    }
  
    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
  }


  /* --------- MOBILE --------- */
  
  @media only screen and (max-width: 400px) {
    .home {
      justify-content: center;
      align-items: center;
      height: 70vh;
    }
  
    .headerContainer {
      margin-left: 0px;
      border-radius: 0px;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
    }
  
    .headerContainer h1 {
      font-size: 40px;
      font-weight: 600;
      padding-left: 0px;
      height: 30px;
      color: #e5d9d1;
    }
  
    .headerContainer p {
      font-size: 30px;
      font-weight: 400;
      padding-left: 0px;
      color: #e5d9d1;
      display: none;
    }
  
    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }

  }