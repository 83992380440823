
.contact {
    margin: 50px;
    width: 90%;
    height: auto;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;    
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .contact .contactLeft {
    height: 100%;
    flex: 50%;
    display: flex;
    justify-content: center;
  }

  .contact .contactLeft img {
    height: 80vh;
    width: 55vh;
    display: flex;
  }
  
  .contact .contactRight {
    height: 100%vh;
    flex: 50%;
    display: flex;
    justify-content: center;
  }
  
  .contact .contactRight h1 {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-weight: 10;
    font-size: 30px;
    padding: 10px;
  }

  .contact .contactRight text {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-weight: 6;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: none;
    vertical-align: middle;
  }

  button {
    background-color: transparent;
    border: none;
    color: #6b1c3b;
    cursor: pointer;
    vertical-align: middle;
}

  button:hover {
    color: #594c46;
    text-shadow: 0px 3px 15px #bba297
  }
  
  form {
    margin-top: 40px;
    border-radius: 15px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    width: 50vh;
    height: auto;
    padding: 10px;
  }
  
  form input {
    height: 40px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
  }
  
  form textarea {
    margin-top: 15px;
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
  }

  input::placeholder,
  textarea::placeholder {
    color: #998887;
    font-family: Arial, Helvetica, sans-serif;
  }

  form input:focus,
  textarea:focus {
    outline: none;
  }
  
  form label {
    margin-top: 15px;
    color: #6b1c3b;
  }
  
  form button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    border-radius: 15px;
    border-color: #594c46;
    background-color: #d4d4d4;
    color: #6b1c3b;
    font-size: 15px;
    cursor: pointer;
  }

  form:hover {
    box-shadow: 0px 3px 15px #321a24;
    transition: 0.3s ease-in;
    cursor: pointer;
  }

/* --------- MEDIUM WINDOW --------- */


@media only screen and (max-width: 1000px) {

  .contact {
    margin: 20px;
    align-items: center;
    justify-content: center;
  }

  .contact .contactLeft {
    height: 100%;
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }

  .contact .contactLeft img{
    height: 57vh;
    width: 40vh;
    display: flex;
  }

  .contact .contactRight {
    height: 100%;
    flex: 50%;
    background-size: cover;
    padding-left: 20px;
    justify-content: center;
}

.contactRight .socialMedia {
    justify-content: center;
    flex: 100%; 
}

.contactRight .contactMe form {
    justify-content: center;
    width: 38vh;
}

}


/* --------- SMALL WINDOW --------- */


@media only screen and (max-width: 700px) {

  .contact {
    margin: 20px;
    width: 90%;
    height: auto;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;    
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

  .contact .contactLeft {
    display: none;
  }

  .contact .contactRight {
    height: 100%;
    width: 100%;
    background-size: cover;
    padding-left: 00px;
    justify-content: center;
    align-items: center;
}

.contactRight .socialMedia {
    justify-content: center;
    flex: 100%; 
}

.contactRight .contactMe form {
    justify-content: center;
    width: 40vh;
}

}

/* --------- MOBILE --------- */

@media only screen and (max-width: 400px) {
  
  .contact {
    margin: 20px;
    width: 90%;
    height: auto;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;    
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

  .contact .contactLeft {
    display: none;
  }

  .contact .contactRight {
    height: 100%;
    width: 100%;
    background-size: cover;
    padding-left: 00px;
    justify-content: center;
    align-items: center;
}

.contactRight .socialMedia {
    justify-content: center;
    flex: 100%; 
}

.contactRight .contactMe form {
    justify-content: center;
    width: 37vh;
}

}