.Navbar {
    width: 100%;
    height: 100px;
    background-color: #e5d9d1;
    display: flex;
    padding-bottom: 5px;
    flex-direction: row;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

#close .Navbar {
  height: 100px;
}
  
  .Navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    padding-left: 100px;
  }
  
  .Navbar .leftSide img {
    width: 180px;
  }
  
  .Navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Navbar a {
    color: #6b1c3b;
    text-decoration: none;
    margin: 20px;
  }

  .Navbar a:hover {
    color: #a67f6e;
  }
  
  .Navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: #6b1c3b;
    cursor: pointer;
  }
  
  .Navbar .rightSide svg {
    font-size: 40px;
  }

  .Navbar #close .slide {
    display: none;
  }

  

  /* --------- SMALL WINDOW --------- */
  
  @media only screen and (max-width: 700px) {
    .Navbar .rightSide a {
      display: none;
    }

    .Navbar .rightSide button {
      display: inherit;
    }

    .slide {
      position: absolute;
      width:100%;
      background-color: #e5d9d1f8;
      margin-top: 105px;
      text-align: center;
      transition: height .4s ease;
      right: 0;
      z-index: 99;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }

    #open .Navbar {
      height: flex;
    }

    #open .slide {
      height: flex;
      display: grid;
      align-items: center;
      border: solid;
      border-color: #6b1c3b;
      border-top-width: 1px;
      border-bottom: none;
    } 

    .slide li {
      padding : 20px;
      border: solid;
      border-color: #6b1c3c97;
      border-bottom-width: 1px;
      border-top: none;
    }

  }

  /* --------- BIG SCREEN --------- */
  
  @media only screen and (min-width: 700px) {
    .Navbar .rightSide button {
      display: none;
    }
  
    .dropdown {
      display: none;
    }

    .Navbar {
      width: 100%;
      height: 100px;
      background-color: #e5d9d1;
      display: flex;
      padding-bottom: 5px;
      flex-direction: row;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    }
  }

  /* --------- MOBILE --------- */
  
  @media only screen and (max-width: 600px) {
    .Navbar .rightSide a {
      display: none;
    }

    .Navbar .rightSide button {
      display: inherit;
    }

    .slide {
      width:100%;
      background-color: #e5d9d1f8;
      padding-top: 0px;
      text-align: center;
      transition: height .4s ease;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }

    #open .Navbar {
      height: flex;
    }

    #open .slide {
      height: flex;
      display: grid;
      align-items: center;
      border: solid;
      border-color: #6b1c3b;
      border-top-width: 1px;
      border-bottom: none;
      list-style-type: none;
    } 

    .slide li {
      padding : 20px;
      border: solid;
      border-color: #6b1c3c97;
      border-bottom-width: 1px;
      border-top: none;
    }
  }