.pricePage {
    width: 100%;
    margin-top: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 55px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  .pricePage .priceTitle {
    color: #6b1c3b;
    font-weight: 10;
    font-size: 50px;
  }

  .priceInfo {
    text-align: center;
    font-size: 18px;
  }
  
  .priceList {
    width: 70vw;
    padding-top: 16px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  
  /* -------- PRICE ITEM STYLING -------- */
  
  .PriceItem {
    border-radius: 15px;
    width: 300px;
    height: flex;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }
  .PriceItem:hover {
    box-shadow: 0px 3px 15px #290a17;
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .PriceItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .PriceItem h1,
  .PriceItem h2 {
    margin-left: 20px;
    line-height: 16px;
  }

  .PriceItem h2 {
    font-size: 20px;
    font-weight: 6;
  }

  .PriceItem p {
    margin-left: 20px;
    font-size: 14px;
    line-height: 8px;
  }
  


/* --------- MEDIUM WINDOW --------- */


@media only screen and (max-width: 1000px) {

  .pricePage {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

  .pricePage .priceTitle h1 {
    font-size: 80px;
  }

  .priceInfo {
    text-align: center;
    font-size: 18px;
    margin: 10px;
  }
  
  .priceList {
    width: 70vw;
    padding-top: 16px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }


  .PriceItem h1,
  .PriceItem h2 {
    margin-left: 20px;
    line-height: 16px;
  }

  .PriceItem h2 {
    font-size: 20px;
    font-weight: 6;
  }

  .PriceItem p {
    margin-left: 20px;
    font-size: 16px;
    line-height: 8px;
  }
}

/* --------- SMALL WINDOW --------- */


@media only screen and (max-width: 700px) {
  
  .pricePage {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  .pricePage .priceTitle h1 {
    font-size: 80px;
  }

  .priceInfo {
    text-align: center;
    font-size: 18px;
    margin: 10px;
  }
  
  .priceList {
    width: 70vw;
    padding-top: 16px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  .priceList {
    grid-template-columns: 1fr;
  }

  .PriceItem h1,
  .PriceItem h2 {
    margin-left: 20px;
    line-height: 16px;
  }

  .PriceItem h2 {
    font-size: 20px;
    font-weight: 6;
  }

  .PriceItem p {
    margin-left: 20px;
    font-size: 16px;
    line-height: 8px;
  }
}

/* --------- MOBILE --------- */

@media only screen and (max-width: 400px) {

  .pricePage {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  .pricePage .priceTitle h1 {
    font-size: 80px;
  }

  .priceInfo {
    text-align: center;
    font-size: 18px;
    margin: 10px;
  }
  
  .priceList {
    width: 70vw;
    padding-top: 16px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  .priceList {
    grid-template-columns: 1fr;
  }

  .PriceItem h1,
  .PriceItem h2 {
    margin-left: 20px;
    line-height: 16px;
  }

  .PriceItem h2 {
    font-size: 20px;
    font-weight: 6;
  }

  .PriceItem p {
    margin-left: 20px;
    font-size: 16px;
    line-height: 8px;
  }

}
